<template>
  <div>
    <div class="row row-sm">
      <div class="col-3">
        <label for="">Client <span class="text-danger">*</span> </label>
        <select
          v-model="filter.customer_reference"
          class="form-select"
          required
        >
          <option selected value="">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
            :style="
              customer.blocked || customer.fullName.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ customer.fullName }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="toFilter(filter)" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="print(filter)" class="btn btn-secondary">
          <i class="bi bi-printer"></i> Imprimer
        </button>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'payments-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un nouveau paiement
        </button>
      </div>
    </div>
    <br />
    <!-- <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Client</th>
          <th scope="col">Date</th>
          <th scope="col">M. Facture</th>
          <th scope="col">Mode de Paiement</th>
          <th scope="col">M. Payé</th>
          <th scope="col">M. Impayé</th>
          <th scope="col">Créé par</th>
        
        </tr> 
      </thead>
      <tbody>
        <tr v-for="(payment, index) in payments" :key="index++">
          <td>
            {{ index + 1 }} 
          </td>
          <td>
            {{ payment.reference }}
          </td>
          <td>
            {{ payment.fieldFullName }} 
          </td>
          <td>{{ payment.date }}</td>

          <td class="er">
            <span v-if="payment.bookingPrice">
              {{ payment.bookingPrice | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-primary">
            <span>
              {{ payment.paymentMethod }}
            </span>
          </td>
          <td class="text-success fw-bold">
            <span v-if="payment.amount >= 0">
              {{ payment.amount | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-danger">
            <span v-if="payment.subscriptionAmountToPay >= 0">
              {{ payment.subscriptionAmountToPay | toFixedWithSpace }}
            </span>
          </td>
        
          <td>
            <span v-if="payment.user">
              {{ payment.user.name }}
            </span>
          </td>
          
          
          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'payments-details',
                  params: { reference: payment.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col">
            <span class="bg-success py-1 px-2 rounded text-white">
              {{ totalAmount | toFixedWithSpace }}
            </span>
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </tfoot>
    </table> -->
    <data-table
      :headers="headers"
      :data="payments"
      :buttons="buttons"
      :summableColumns="['bookingPrice', 'amount', 'subscriptionAmountToPay']"
      :customers="customers"
      @filter="filterData"
      @print="printData"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DataTable from "../../components/cards/DataTable.vue";

export default {
  components: { DataTable },
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      filter: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
      headers: [
        { label: "Référence", field: "reference" },
        { label: "Client", field: "fieldFullName" },
        { label: "Date", field: "date" },
        { label: "Prix ​​de réservation", field: "amountToPay" },
        { label: "Objectif du paiement ", field: "paymentPurpose" },
        { label: "Mode de Paiement", field: "paymentMethod" },
        { label: "M. Payé", field: "amount" },
        { label: "Créé par", field: "user.name" },
      ],
      buttons: [
        {
          label: "Voir Détails",
          class: "btn btn-primary",
          icon: "bi bi-info-square",
          handler: this.viewDetails,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("payment", {
      payments: "getAll",
      totalAmount: "getTotalAmount",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUser  Role",
    }),
  },
  mounted() {
    this.$store.dispatch("payment/getAll");
    this.$store.dispatch("customer/getAll");
  },
  methods: {
    filterData(filter) {
      this.$store.dispatch("payment/filter", filter);
    },
    printData(filter) {
      this.$store.dispatch("payment/print", filter);
    },
    viewDetails(row) {
      this.$router.push({ name: "payments-details", params: { reference: row.reference } });
    },
    async refresh() {
      return await this.$store.dispatch("payment/getAll");
    },

   
    async print(data) {
      await this.$store.dispatch("payment/print", data);
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3}) +(?!\d))/g, "$1 ");
    },
  },
};
</script>